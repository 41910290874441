import React, { useState, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";

const EmployeeFilter = ({ tasks, onFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const uniqueEmployees = [...new Set(tasks.map((task) => task.assignedEmployeeName))].sort((a, b) =>
      a.localeCompare(b)
    );
    setEmployees(uniqueEmployees);
  }, [tasks]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleEmployeeChange = (employee) => {
    setSelectedEmployees((prev) => {
      const newSelection = prev.includes(employee) ? prev.filter((e) => e !== employee) : [...prev, employee];

      onFilterChange(newSelection);
      return newSelection;
    });
  };

  return (
    <div className="employee-filter">
      <button onClick={handleToggle} className="filter-button">
        <span className="filter-button-text">
          {selectedEmployees.length > 0
            ? `${selectedEmployees.length} Employee${selectedEmployees.length > 1 ? "s" : ""} Selected`
            : "No Employee Filter"}
        </span>
        <span className="filter-button-icon">
          <FaChevronDown />
        </span>
      </button>
      {isOpen && (
        <div className="employee-list">
          {employees.map((employee) => (
            <label key={employee} className="employee-item">
              <input
                type="checkbox"
                checked={selectedEmployees.includes(employee)}
                onChange={() => handleEmployeeChange(employee)}
              />
              <span>{employee}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default EmployeeFilter;
